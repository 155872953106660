.detail__heading {
    font-size: 2rem;
    font-weight: 400;
    padding: 2rem 0;
  }
  
  .detail__para > ol {
    display: block;
  }
  
  .detail__para > ol > li {
    list-style: disc;
    float: left;
  }
  
  .detail__para {
    font-size: 0.9rem;
    color: var(--color-grey);
  }
  
  .detail__para:nth-child(3) > p {
    margin-bottom: 1rem;
  }
  
  .info__two {
    margin-bottom: 20px;
  }
  
  .details__container {
    padding: 5rem;
    background: var(--color-text-bg);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  