.achievements__container {
    background: linear-gradient(135deg, #ddcbf1 10%, #49108b, #000);
    color: var(--color-white);
    padding: 2rem;
    text-align: center;
  }
  
  .achievements__container P {
    color: var(--color-white-variant);
  }
  
  .numbers__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 3rem;
  }
  
  .num {
    padding: 3rem 0;
  }
  
  .num h1 {
    color: var(--color-white);
  }
  
  .num p {
    color: var(--color-white-variant);
  }
  
  @media screen and (max-width: 600px) {
    .num h1 {
      font-size: 2rem;
    }
  }
  