.products{
    width: 100%;
    border: 1px solid transparent;
    background-color: var(--color-white);
    text-align: center;
    padding: 5rem 0 ;
    }

    .products_title {
        font-size: 60px;
        /* margin-bottom: 1rem; */
        color: var(--color-primary);
    }

    .products > p {
        text-transform: uppercase;
        padding: 0 10rem;
        font-weight: 300;
        font-size: 14px;
        letter-spacing: 2px;
        margin-bottom: 5rem;

    }

.container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    display: inline-block;
    margin-right: 1%;
}

.card:hover {
    box-shadow: 2px 4px 6px var(--color-grey);
}

.container .card {
    position: relative;
    width: 320px;
    height: 450px;
    background: var(--color-text-bg);
    border-radius: 20px;
    overflow: hidden;
}

.container .card:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-secondary);
    clip-path: circle(150px at 80% 20%);
    transition: 0.5s ease-in-out;
}

.container .card:hover:before {
    clip-path: circle(300px at 80% -20%);
}

.container .card:after {
    content: "Personal";
    position: absolute;
    top: 50%;
    left: -20%;
    font-size: 7em;
    font-weight: 800;
    font-style: italic;
    color: var(--color-white-variant);
}

.container .business:after{
    content: "Business";
}
.container .home:after{
    content: "Home";
}
.container .vehical:after{
    content: "Vehicle";
}

.container .card .imgBx {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    width: 100%;
    height: 100%;
    transition: .5s;
}

.container .card:hover .imgBx {
    top: 0%;
    transform: translateY(-25%);
    /* bug  */
}

.container .card .imgBx img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(3deg);
    width: 170px;
}

.container .card .contentBx {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    text-align: center;
    transition: 1s;
    z-index: 90;
}

.container .card:hover .contentBx {
    height: 210px;
}

.container .card .contentBx h2 {
    position: relative;
    font-weight: 600;
    letter-spacing: 1px;
    color: var(--color-text);
}

.container .card .contentBx .details h3,
.container .card .contentBx {
    color: var(--color-primary);
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-right: 10px;
}


.container .card .contentBx a {
    display: inline-block;
    padding: 10px 20px;
    background: #fff;
    border-radius: 4px;
    margin-top: 10px;
    text-decoration: none;
    font-weight: 600;
    color: #111;
    opacity: 0;
    transform: translateY(50px);
    transition: .5s;
}

.container .card:hover .contentBx a {
    opacity: 1;
    transform: translateY(0px);
    transition-delay: .7s;
} 

@media screen and (max-width: 1200px) {
    .container .card .imgBx img {
        width: 100px;
    }

    .container .card .contentBx {
        height: 140px;
    }
   }
   
   @media screen and (max-width: 600px) {

    .products > p {
        width: 100%;
        margin: 1rem;
        padding: 0;
    }

    .container .card .imgBx img {
        width: 150px;
    }
   }