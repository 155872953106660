* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
}

:root {
    --color-secondary:#ddcbf1 ;
    --color-primary: #49108b;
    --color-three:#3b4246 ;
    --color-text-bg:#f5faff ;
    --color-grey: #8c8c8c;
    --color-primary-dull: ;
    --color-white: #ffffff;
    --color-white-variant: #dcdcdc;
    --color-text: #030303;
    --color-bg: #37445a;
    --color-lb: #84f8fc;
  
    --transition: all 400ms ease;
  
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
  }

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
  }

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-white);
    color: var(--color-text);
    line-height: 1.7; 
  }

  .container {
    width: var(--container-width-lg);
    margin: 0 auto;
  }

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section > h2, section > h5 {
  text-align: center;
  color: var(--color-primary-dull);
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-grey);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 2px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
  border-color: var(--color-primary);
}

.btn-primary {
  /* background: var(--color-white); */
  color: var(--color-text);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}
