.faq__container {
    background-color: var(--color-white);
    padding: 6rem;
}

.faq__container > h1{
    text-align: center;
}

.faq__container > p {
    color: var(--color-grey);
    text-align: center;
    margin-bottom: 3rem;
}
