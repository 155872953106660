.check_score{
    width: 100%;
    height: 40vh;
    background: var(--color-white);
    display: flex;  
    flex-wrap: wrap;
    background: linear-gradient(135deg, #ddcbf1 10%, #49108b, #000);
}

.display{
    width: 50%;
    height: 40vh;
}

.display img {
    height: 40vh;
    /* width: 60%; */
}



.content{
    width: 50%;
    height: 40vh;
    padding: 25px;
}

.content h1 {
    font-size: 30px;
    color: var(--color-white);
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 5vh;
}

.content p{
    color: var(--color-white);
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-right: 10px;
    margin-top: 2vh;
}

.trybtn {
    width: max-content;
    display: inline-block;
    color: var(--color-white);
    padding: 0.75rem 2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 2px solid var(--color-white);
    transition: var(--transition);
    width: 15rem;
    text-align: center;
    margin-top: 5vh;
}

.trybtn:hover {
    background-color: var(--color-white);
    color: var(--color-primary);
    border-color: var(--color-white);
  }

  @media screen and (max-width: 1200px) {
   .content > h1 {
    font-size: 20px;
   }

   .content p {
    font-size: 7px;
   }

   .display img {
    margin-top: 40px;
    height: 20vh;
   }
  }
  
  @media screen and (max-width: 600px) {

    .check_score {
        height: 80vh;
        text-align: center;
    }
    .display {
        width: 100%;
    }

    .content {
        width: 100%;
    }

    .content p {
        font-size: 12px;
    }
  }