.heading_review {
    margin-top: 6rem;
    text-align: center;
}

.para_review {
    text-align: center;
    color: var(--color-grey);
}

.Testimonials__main-container{
    margin-top: 0;
}