footer {
    /* background: #f2f2f2; */
    background-color: var(--color-text-bg);
  }
  
  .footer__info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding-top: 4rem;
  }

  .title__address {
    padding: 0 5rem;
  }
  
  .title__address > h2 {
    color: var(--color-primary);
    font-size: 2.6rem;
    font-weight: 600;
  }
  
  .title__address > h3 {
    font-size: 1.2rem;
    margin-top: 2rem;
  }
  
  .title__address > p {
    color: var(--color-grey);
    margin-top: 2.3rem;
  }
  
  .title__address > a {
    font-size: 2rem;
  }

  .title__address > a:hover {
    color: var(--color-primary);
  }
  
  .horizontal-line {
    margin-top: 2rem;
    border-top: 1px solid var(--color-grey);
  }
  
  .footer__credits > p {
    color: var(--color-grey);
    padding: 1rem;
  }
  
  .extra__links {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .extra__links a:not(:last-child) {
    color: var(--color-text);
  }
  
  @media screen and (max-width: 600px) {
    .footer__info {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }
  
    .title__address {
      grid-template-columns: 1fr;
    }

    .extra__links {
      display: block;
      align-items: center;
      text-align: center;
      width: 100%;
    }

    .contact > h4 {
      display: none;
    }
  }


  @media screen and (max-width: 1200px) {
    .extra__links {
      margin-top: 5rem;
      display: block;
      align-items: center;
      text-align: center;
      width: 100%;
    }
  }

  