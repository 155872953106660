.opening{
    height: 100%;
    width: 100%;
}

.desc {
    text-align: center;
    padding-top: 10rem;
}


.desc > h1 {
    font-size: 4rem;
    font-weight: 300;
    color: var(--color-text);
}

.desc > h1 > span {
    color: var(--color-primary);
    font-weight: 500;
}

.desc > h2 {
    font-size: 3.5rem;
    font-weight: 300;
    color: var(--color-text);
}

.desc > h2 > span {
    color: var(--color-primary);
}

.tag {
    font-size: 2rem;
    left: 3rem;
    margin-top: 2rem;
    font-weight: 500;
}

  .prevouse-word {
    position: relative;
    margin-left: -10rem;
  }
  
  span.item{
    position: absolute;
    color: var(--color-primary);
  }
  
  span.item:nth-child(1) {
    animation: flipUp 6s cubic-bezier(0.68, -0.55, 0.26, 1.55) 0s both infinite;
  }
  span.item:nth-child(2) {
    animation: flipUp 6s cubic-bezier(0.68, -0.55, 0.26, 1.55) 1.5s both infinite;
  }
  span.item:nth-child(3) {
    animation: flipUp 6s cubic-bezier(0.68, -0.55, 0.26, 1.55) 3s both infinite;
  }
  span.item:nth-child(4) {
    animation: flipUp 6s cubic-bezier(0.68, -0.55, 0.26, 1.55) 4.5s both infinite;
  }
  /* span.item:nth-child(5) {
    animation: flipUp 9s cubic-bezier(0.68, -0.55, 0.26, 1.55) 6s both infinite;
  } */
  
  /* ANIMATION */
  
  @keyframes flipUp {
    0% {
     opacity: 0;
     margin-top: -10px;
    }
    13.2%{
      opacity: 1;
      margin-top: 0;
      transform: rotateX(0deg);
    }
    33%,100% {
      opacity: 0;
       margin-top: 0;
      transform: rotateX(90deg);
      
    }
  }
  
  .hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;}

  .user_number {
    height: auto;
    width: auto;
    margin-top: 4rem;
    border: 2px solid var(--color-primary);
    border-radius: 10px;
    display: flex;
    background-color: var(--color-white);
    justify-content: center;
    align-items: center;
  }

  .user_number > input {
    height: 100%;
    padding: 10px;
    font-size: 20px;
    color: var(--color-grey);
    outline: none;
    /* border: 1px solid red; */
  }

  .user_number > p:first-child {
    font-size: 20px;
    color: #a9a9a9;
    padding: 5px 8px;
  }

  .user_number > p:nth-child(2) {
    color: #a9a9a9;
    font-size: 30px;
    margin: 0 auto;
  }

  .mobile_number {
    color: var(--color-white);
    background-color: var(--color-primary);
    height: max-content;
    margin-top: 0;
    margin-right: 2px;
  }

  .mobile_number p{
    font-size: 20px;
  }

  .number_form input{
    height: 100%;
    font-size: 20px;
    align-items: center;
    background-color: transparent;
    outline: none;
    padding: 1rem 1rem;
  }

  .btn_combo {
    margin-top: 7rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }

    .opening_btn {
      overflow:hidden; 
      padding: 0.6rem 5rem;
      display: grid;
      background-color: var(--color-primary);
      color: var(--color-white);
      position:relative;
      font:14px/50px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
      box-shadow:rgba(0,0,0,.15) 0 1px 2px;
      transition: all 1s;
      /* border: 2px solid var(--color-white); */
      border-radius: 4px;
    }
    .opening_btn:hover {
      box-shadow:rgba(0,0,0,.3) 0 8px 25px, rgba(0,0,0,.2)0 1px;
      text-shadow:#fff 0 0 12px;
    }
    .opening_btn:active {
      box-shadow:rgba(0,0,0,.2) 0 8px 25px;
      transition: all 0s;
    }
    .opening_btn span { 
      position:relative;
      z-index:9;
    }
    .opening_btn:before, a:after {
      position:absolute;
      top:0;
      left:0;
      z-index:7;
      display:block;
      content:" ";
    }
    .opening_btn:after {
      height:100%;
      width:2000px;
      transition: all .8s;
      background: linear-gradient(135deg,  #b78458 0%,#935f39 19%,#aa6740 37%,#ffcece 50%,#ea2803 66%,#ff6600 75%,#c72200 100%);
    }
    .opening_btn:before {
      height:26px;
      width:100%;
      top:-50px;
      z-index:10;
      opacity:0;
      background-color:rgba(255,255,255,.15);
      transition: all .5s;
    }
    .opening_btn:hover:before {
      top:0;
      opacity:1;
    }
    .opening_btn:hover:after {
      left:-1750px;
    }
    .opening_btn:active:before {
      height:100%;
      background-color:rgba(255,255,255,.8);
      box-shadow:inset rgba(255,255,255,.5)0 0 25px;
      transition: all 0s;
    }

    @media (max-width: 1200px) {
      .desc > h1 {
        font-size: 3rem;
        font-weight: 300;
        color: var(--color-text);
    }

    .desc > h2 {
      font-size: 2.5rem;
      font-weight: 300;
      color: var(--color-text);
  }

    }
    
    @media (max-width: 600px) {

      .desc h1 {
        font-size: 2rem;
      }
      /* .hero h2 {
        font-size: 10px;
      } */
    
      .desc h2 {
        font-size: 1rem;
      }

      .tag {
        text-align: center;
        font-size: 1rem;
      }

      .btn_combo {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      
    }