 nav{
    padding: 1rem;
    display: flex; 
    align-items: center;
    justify-content: space-between; 
    /* background-color: #fff; */
    position: fixed;
    width: 100%;
    z-index: 199;
    /* box-shadow: 0px 5px 4px var(--color-white-variant); */
  }

  .navbar-scroll {
    background-color: var(--color-white);
    box-shadow: 2px 3px 5px var(--color-grey);
  }

  .navbar__logo > h2 {
    color: var(--color-primary);
    font-weight: 800;
  }

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navbar a {
  text-decoration: none;
  font-size: 20px;
  color: var(--color-text);
  position: relative;
  margin-right: 20px;
  transition: all .2s;
  overflow: hidden;
}
.navbar a:hover {
  color: var(--color-primary);
  font-weight: 800;
}


.navbar a:hover::before {
  left: 100%;
}

.navbar__logo img{
  width: 150px;
  height: 60px;
}

@media screen and (max-width: 600px) {
  .navbar {
    display: none;
  }

  .nav {
    padding: 0;
  }

  .btn-primary{
    margin-right: 20px;
  }
}